import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Card, CardBody, Col, Label, Row, Spinner } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  checkVehicleNumber,
  createVehicle,
  getBrands,
  getCategories,
  getCategoriesDetails,
  getClients,
  getModels,
  getUsers,
  getVariants,
  resetState,
  updateVehicle,
} from "store/actions"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import moment from "moment"
import UseImageUploader from "components/imageUploader/useImageUploader"
import { WithContext as ReactTags } from "react-tag-input"
import Switch from "react-switch"
import { useLocation } from "react-router-dom"
import { map } from "lodash"

function Form({ updateData }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const modelLoading = false
  const [tags, setTags] = useState([])
  const [keywords, setKeywords] = useState([])

  const {
    model,
    brand,
    varient,
    client,
    vehicleDetails,
    vehicles,
    staff,
    categories,
    vehicleNumber,
  } = useSelector(state => ({
    model: state.Model.models?.models,
    brand: state.Brand.brands?.brands,
    varient: state.Varient.variants?.variants,
    client: state?.Client?.clients?.clients,
    vehicleDetails: state.Vehicle?.vehicleDetails,
    vehicleNumber: state.Vehicle?.vehicleNumber,
    vehicles: state.Vehicle?.vehicles?.vehicles,
    staff: state?.Members?.users?.users,
    categories: state?.Categories?.categories?.categories,
  }))

  console.log(params)

  console.log(categories)
  const updateType = location?.state

  console.log(updateType)
  console.log(location)

  const [images, setImages] = useState([])
  console.log(images, "images")

  const [modelValue, setModelValue] = useState("Select Model...")
  const [modelId, setModelValueId] = useState()
  const [modelSearch, setModelSearch] = useState("")

  const handleEnters = textEntered => {
    setModelSearch(textEntered)
  }

  function handlerFinalValueModel(event) {
    if (event?.label === "Clear") {
      setModelValue(event.value)
      setModelValueId(null)
    } else {
      setModelValue(event.label)
      setModelValueId(event.value)
    }
  }

  const modelOptions = [
    {
      label: "Clear",
      value: "select a model",
    },
    ,
    {
      options: (model?.length >= 1 ? model : [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results._id,
      })),
    },
  ]

  const [brandValue, setBrandValue] = useState("Select Brand...")
  const [brandId, setBrandId] = useState()
  const [brandSearch, setBrandSearch] = useState("")

  const handleEnter = textEntered => {
    setBrandSearch(textEntered)
  }

  function handlerFinalValuesBrand(event) {
    if (event?.label === "Clear") {
      setBrandValue(event.value)
      setBrandId(null)
    } else {
      setBrandValue(event.label)
      setBrandId(event.value)
    }
  }

  const brandOptions = [
    {
      label: "Clear",
      value: "select a brand",
    },
    {
      options: (brand?.length >= 1 ? brand : [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results._id,
      })),
    },
  ]

  const [varientValue, setVarientValue] = useState("Select Variant...")
  const [varientId, setVarientId] = useState()
  const [varientSearch, setVarientSearch] = useState("")

  const handleEntered = textEntered => {
    setVarientSearch(textEntered)
  }

  function handleFinalValuesVarient(event) {
    if (event?.label === "Clear") {
      setVarientValue(event?.value)
      setVarientId(null)
    } else {
      setVarientValue(event.label)
      setVarientId(event.value)
    }
  }

  const varientOptions = [
    {
      label: "Clear",
      value: "select a varient",
    },
    {
      options: (varient?.length >= 1 ? varient : [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results._id,
      })),
    },
  ]

  const [ownerShipStatusValue, setOwnerShipStatusValue] =
    useState("first_owner")

  const [oilTypeValue, setOilTypeValue] = useState("diesel")

  const [bookedClient, setBookedClient] = useState("Select Booked Client...")
  const [bookedClientId, setBookedClientId] = useState()
  const [bookedClientSearch, setBookedClientSearch] = useState("")

  function handlerBookedClientFinalValues(event) {
    setBookedClient(event.label)
    setBookedClientId(event.value)
  }
  const handleEnteredValue = textEntered => {
    setBookedClientSearch(textEntered)
  }

  const [bookedStaff, setBookedStaff] = useState("Select Staff...")
  const [bookedStaffId, setBookedStaffId] = useState()
  const [bookedStaffSearch, setBookedStaffSearch] = useState("")

  const [soldStaff, setSoldStaff] = useState("Select Staff...")
  const [soldStaffId, setSoldStaffId] = useState()
  const [soldStaffSearch, setSoldStaffSearch] = useState("")

  function handlerBookedStaffFinalValues(event) {
    setBookedStaff(event.label)
    setBookedStaffId(event.value)
  }

  const handleEnteredValueStaff = textEntered => {
    setBookedStaffSearch(textEntered)
  }
  useEffect(() => {
    setBookedStaffId(vehicleDetails?.bookedBy?._id)
    setBookedStaff(vehicleDetails?.bookedBy?.name)
    setSoldStaffId(vehicleDetails?.soldBy?._id)
    setSoldStaff(vehicleDetails?.soldBy?.name)
  }, [vehicleDetails])

  useEffect(() => {
    dispatch(
      getUsers("", "", bookedStaffSearch || soldStaffSearch, "", "member")
    )
  }, [dispatch, bookedStaffSearch, soldStaffSearch, "member"])

  const staffBookedOptions = [
    {
      options: (staff?.length >= 1 ? staff : [])?.map((results, index) => ({
        key: index,
        label: results?.name,
        // (
        //   <div>
        //     {results?.name}
        //     {results?.memberRole ? (
        //       <small>{`(${results?.memberRole})`}</small>
        //     ) : (
        //       ""
        //     )}
        //   </div>
        // ),
        value: results._id,
      })),
    },
  ]
  useEffect(() => {
    setSoldStaffId(vehicleDetails?.soldBy?._id)
    setSoldStaff(vehicleDetails?.soldBy?.name)
    setBookedClientId(vehicleDetails?.bookedBy?._id)
    setBookedClient(vehicleDetails?.bookedBy?.name)
  }, [vehicleDetails])

  function handlerSoldStaffFinalValues(event) {
    setSoldStaff(event.label)
    setSoldStaffId(event.value)
  }
  const handleEnteredValueSold = textEntered => {
    setSoldStaffSearch(textEntered)
  }

  const staffSoldOptions = [
    {
      options: (staff?.length >= 1 ? staff : [])?.map((results, index) => ({
        key: index,
        label: results?.name,
        // <div>
        //   {results?.name}
        //   {results?.memberRole ? (
        //     <small>{`(${results?.memberRole})`}</small>
        //   ) : (
        //     ""
        //   )}
        // </div>
        value: results._id,
      })),
    },
  ]

  const [soldClient, setSoldClient] = useState("Select Sold Client...")
  const [soldClientId, setSoldClientId] = useState()
  const [soldClientSearch, setSoldClientSearch] = useState("")

  function handlerSoldClientFinalValues(event) {
    setSoldClient(event.label)
    setSoldClientId(event.value)
  }
  const handleEnteredValues = textEntered => {
    setSoldClientSearch(textEntered)
  }
  const clientOptions = [
    {
      options: (client?.length >= 1 ? client : [])?.map((results, index) => ({
        key: index,
        label: results?.name,
        value: results._id,
      })),
    },
  ]

  // Category
  const [categoryId, setCategoryId] = useState([])
  const [categorySearch, setCategorySearch] = useState("")
  const [selectedMulti, setSelectedMulti] = useState(null)

  // const categoryOptions = [
  //   {
  //     options: (categories?.length >= 1 ? categories : [])?.map(
  //       (results, index) => ({
  //         key: index,
  //         label: results?.name,
  //         value: results?._id,
  //       })
  //     ),
  //   },
  // ]

  const optionGroup = categories.map(results => {
    return {
      label: results.name,
      value: results._id,
    }
  })

  function handleMulti(categoryData) {
    setSelectedMulti(categoryData)
    const arrangedCategories = categoryData.map(a => a.value)
    setCategoryId(arrangedCategories)
  }

  console.log(categoryId, "category")
  useEffect(() => {
    dispatch(getCategories(1, 10, categorySearch))
  }, [dispatch, categorySearch])

  useEffect(() => {
    const categoryList = vehicleDetails?.categories
    const newList = map(categoryList, result => ({
      ...selectedMulti,
      label: result?.name,
      value: result?._id,
    }))
    setSelectedMulti(newList)

    const valueList = map(categoryList, result => result?._id)
    setCategoryId(valueList)
  }, [vehicleDetails?.categories])

  // reset states

  // const initialState = {
  //   name: "",
  //   description: "",
  //   isFeatured: false,
  //   tags: [],
  //   images: [],
  // }
  // const [values, setValues] = useState(initialState)

  // const resetState = () => {
  //   setValues(initialState)
  // }

  const handleValidSubmit = values => {
    const updateData = {
      ...vehicleDetails,
      ...values,
      kiloMeter: parseInt(values?.kiloMeter) || 0,
      makeYear: parseInt(values.makeYear) || 0,
      initialPurchaseYear: parseInt(values.initialPurchaseYear) || 0,
      model: modelId,
      brand: brandId,
      variant: varientId,
      ownershipStatus: ownerShipStatusValue,
      fuelType: oilTypeValue,
      bookedClient: bookedClientId,
      soldClient: soldClientId,
      images: images,
      tags: keywords,
      categories: categoryId,
      isFeatured: isFeatured,
      bookedBy: bookedStaffId && bookedStaffId,
      soldBy: soldStaffId && soldStaffId,
      isSold: soldStaffId || soldClientId ? true : false,
      isBooked: bookedStaffId || bookedClientId ? true : false,
    }

    if (params?.id) {
      dispatch(updateVehicle(updateData, vehicleDetails?._id, history))
    } else {
      const createData = {
        ...values,
        isFeatured: isFeatured,
        tags: keywords,
        images: images,
        categories: categoryId,
      }

      console.log("createData => ", createData)

      dispatch(createVehicle(createData, history))
    }
  }
  useEffect(() => {
    dispatch(resetState())
  }, [dispatch])

  const bookedDate = moment(vehicleDetails?.bookedDate).format("yyyy-MM-DD")
  const soldDate = moment(vehicleDetails?.soldDate).format("yyyy-MM-DD")

  const handleDeleteTag = i => {
    setTags(tags.filter((tag, index) => index !== i))
    const arrangedKeywords = tags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleAddition = tag => {
    setTags([...tags, tag])
    const arrangedKeywords = [...tags, tag].map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice()
    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)
    // re-render
    setTags(newTags)
    const arrangedKeywords = newTags.map(a => a.text)
    setKeywords(arrangedKeywords)
  }

  const handleTagClick = index => {
    console.log("The tag at index " + index + " was clicked")
  }

  const suggestions = vehicles?.map(vehicles => {
    return {
      id: vehicles._id,
      text: vehicles.name,
    }
  })
  const KeyCodes = {
    comma: 188,
    enter: 13,
  }
  const delimiters = [KeyCodes.comma, KeyCodes.enter]

  const [isFeatured, setIsFeatured] = useState(false)

  useEffect(() => {
    const keyWordList = vehicleDetails?.tags
    const modifiedList = []
    keyWordList?.map(i => modifiedList?.push({ id: i, text: i }))

    setTags(modifiedList)
    setKeywords(keyWordList)
  }, [vehicleDetails])

  useEffect(() => {
    setIsFeatured(vehicleDetails?.isFeatured)
  }, [vehicleDetails])

  useEffect(() => {
    if (updateData) {
      setModelValue(vehicleDetails?.model?.name)
      setModelValueId(vehicleDetails?.model?._id)

      setBrandValue(vehicleDetails?.brand?.name)
      setBrandId(vehicleDetails?.brand?._id)

      setBookedClient(vehicleDetails?.bookedClient?.name)
      setBookedClientId(vehicleDetails?.bookedClient?._id)

      setVarientValue(vehicleDetails?.variant?.name)
      setVarientId(vehicleDetails?.variant?._id)

      setSoldClient(vehicleDetails?.soldClient?.name)
      setSoldClientId(vehicleDetails?.soldClient?._id)

      setOwnerShipStatusValue(vehicleDetails?.ownershipStatus)

      setOilTypeValue(vehicleDetails?.fuelType)
      setImages(vehicleDetails?.images)

      setCategoryId(vehicleDetails?.categories)
    }
  }, [updateData, vehicleDetails])

  // apis
  useEffect(() => {
    dispatch(getModels(1, 10, modelSearch, brandId))
  }, [modelSearch, brandId])

  useEffect(() => {
    dispatch(getBrands(1, "", 10, brandSearch))
  }, [brandSearch])

  useEffect(() => {
    dispatch(getVariants(1, 10, varientSearch, modelId))
  }, [varientSearch, modelId])

  useEffect(() => {
    if (bookedClientSearch) {
      dispatch(getClients(1, 10, bookedClientSearch, "", "", ""))
    } else {
      dispatch(getClients(1, 10, soldClientSearch, "", "", ""))
    }
  }, [bookedClientSearch, soldClientSearch])

  const handleChangeOwnershipStatus = value => {
    if (ownerShipStatusValue?.includes(value) === true) {
      setOwnerShipStatusValue("")
    } else {
      setOwnerShipStatusValue(value)
    }
  }
  const handleChangeFuelType = value => {
    if (oilTypeValue?.includes(value) === true) {
      setOilTypeValue("")
    } else {
      setOilTypeValue(value)
    }
  }

  console.log(updateType)

  const handleVehicleNumber = value => {
    console.log(value)
    const data = {
      vehicleNumber: value,
    }
    if (updateType === "vehicle") {
      dispatch(checkVehicleNumber(data, params?.id))
    } else {
      dispatch(checkVehicleNumber(data, ""))
    }
  }

  const [onCheckVehicleNumber, setOnCheckVehicleNumber] = useState("")
  const [onCheckVehicleNumberLength, setOnCheckVehicleNumberLength] =
    useState("")

  console.log(onCheckVehicleNumber)
  console.log(vehicleNumber)

  return (
    <div>
      <AvForm
        onValidSubmit={(e, v) => {
          handleValidSubmit(v)
        }}
      >
        <Row>
          {updateType === "vehicle" && (
            <Col sm="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col
                      md={6}
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <div className="mb-4 d-flex align-items-end">
                        <div className="w-100">
                          <label>
                            Name<span className="text-danger">*</span>
                          </label>
                          <AvField
                            name="name"
                            type="text"
                            className="form-control"
                            value={updateData ? vehicleDetails?.name : ""}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter a name",
                              },
                            }}
                          />
                        </div>
                        <div className="mx-4 d-flex align-items-end mb-1">
                          <p className="mb-1"> Featured</p>
                          <Switch
                            uncheckedIcon={<OffSymbol />}
                            checkedIcon={<OnSymbol />}
                            className="w-100 ms-2 mb-sm-8"
                            onColor="#EC942C"
                            onChange={v => {
                              setIsFeatured(v)
                            }}
                            checked={isFeatured ?? false}
                          />
                        </div>
                      </div>
                      <div className="w-100 me-1 my-2">
                        <label>
                          Vehicle No<span className="text-danger">*</span>
                        </label>
                        <AvField
                          name="vehicleNumber"
                          type="text"
                          className="form-control"
                          value={
                            updateData ? vehicleDetails?.vehicleNumber : ""
                          }
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Please enter a Vehicle Number",
                            },
                            // maxlength: {
                            //   value: 10,
                            //   errorMessage: "maximum 10 digit is allowed",
                            // },
                            // minlength: {
                            //   value: 8,
                            //   errorMessage: "minimum 8 digit is required",
                            // },

                            // pattern: {
                            //   value: /^[A-Z]{2}[ -]?[0-9]{2}[ -]?[0-9]{4}$/,
                            //   errorMessage:
                            //     "Please enter a valid Vehicle Number",
                            // },
                            // pattern: {
                            //   value: "[A-Z]w+",
                            //   errorMessage:
                            //     "Please enter a valid Vehicle Number",
                            // },
                          }}
                          onChange={e => {
                            setOnCheckVehicleNumber(e.target.value)
                            handleVehicleNumber(e.target.value)
                          }}
                        />

                        {onCheckVehicleNumber &&
                          onCheckVehicleNumber?.length <= 10 && (
                            <>
                              {vehicleNumber?.vehicleNumber === true ? (
                                <small className="text-success">
                                  Available
                                </small>
                              ) : (
                                <small className="text-danger">
                                  Not Available
                                </small>
                              )}
                            </>
                          )}
                      </div>
                      <Col md={8}>
                        <label>
                          Category<span className="text-danger">*</span>
                        </label>
                        <Select
                          value={selectedMulti}
                          isMulti={true}
                          onChange={e => {
                            handleMulti(e)
                          }}
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                          isLoading={modelLoading}
                          isRequired={true}
                        />
                      </Col>

                      <UseImageUploader
                        imageSize={4}
                        imagesPreview={images}
                        setImagesPreview={setImages}
                        uploadUrl={"/focusmotors"}
                        isMultiple
                      />
                    </Col>
                    <Col md={6} className="p-2 ">
                      <AvField
                        name="description"
                        type="textarea"
                        clasnName="form-control"
                        label="Description"
                        value={updateData ? vehicleDetails?.description : ""}
                        validate={{
                          required: {
                            value: false,
                            errorMessage: "Please enter a name",
                          },
                        }}
                        style={{ height: 120 }}
                      />
                      <div className="col-12 mt-2">
                        <Label htmlFor="lbltype" className="form-label">
                          Tags
                        </Label>
                        <ReactTags
                          tags={tags}
                          placeholder="Type TEXT and enter"
                          suggestions={suggestions}
                          delimiters={delimiters}
                          handleDelete={handleDeleteTag}
                          handleAddition={handleAddition}
                          handleDrag={handleDrag}
                          handleTagClick={handleTagClick}
                          // inputFieldPosition="inline"
                          autocomplete
                          allowUnique
                          inline
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md={6} className="mt-3">
                      <label>Ownership</label>
                      <div
                        className="btn-group mt-0 mt-xl-0 d-flex align-items-center justify-content-start"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        {map(ownerShip, (item, key) => (
                          <div key={key} className="me-3">
                            <input
                              type="radio"
                              className="btn-check"
                              name="btnradio-Ownership"
                              id={item.value + "Ownership"}
                              autoComplete="off"
                              checked={ownerShipStatusValue === item.value}
                              value={item.value}
                              onClick={e =>
                                handleChangeOwnershipStatus(e.target.value)
                              }
                            />
                            <label
                              className="btn btn-outline-warning"
                              htmlFor={item.value + "Ownership"}
                            >
                              {item.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col md={6} className="mt-3">
                      <label>Fuel Type</label>
                      <div
                        className="btn-group mt-0 mt-xl-0 d-flex align-items-center justify-content-start"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        {map(FUEL_TYPE, (item, key) => (
                          <div key={key} className="me-3">
                            <input
                              type="radio"
                              className="btn-check"
                              name="btnradio-fuelType"
                              id={item.value + "fuelType"}
                              autoComplete="off"
                              checked={oilTypeValue === item.value}
                              value={item.value}
                              onClick={e =>
                                handleChangeFuelType(e.target.value)
                              }
                            />
                            <label
                              className="btn btn-outline-warning"
                              htmlFor={item.value + "fuelType"}
                            >
                              {item.title}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col md={4} className="mt-3">
                      <label>Brand</label>
                      <Select
                        onInputChange={handleEnter}
                        value={brandValue}
                        placeholder={brandValue}
                        onChange={handlerFinalValuesBrand}
                        options={brandOptions}
                        classNamePrefix="select2-selection"
                        isLoading={modelLoading}
                        isRequired={true}
                      />
                    </Col>
                    <Col md={4} className="mt-3">
                      <label>Model</label>
                      <Select
                        onInputChange={handleEnters}
                        value={modelValue}
                        placeholder={modelValue}
                        onChange={handlerFinalValueModel}
                        options={modelOptions}
                        classNamePrefix="select2-selection"
                        isLoading={modelLoading}
                        isRequired={true}
                      />
                    </Col>

                    <Col md={4} className="mt-3">
                      <label>Variant</label>
                      <Select
                        onInputChange={handleEntered}
                        value={updateData ? vehicleDetails?.varient : ""}
                        placeholder={varientValue}
                        onChange={handleFinalValuesVarient}
                        options={varientOptions}
                        classNamePrefix="select2-selection"
                        isLoading={modelLoading}
                        isRequired={true}
                      />
                    </Col>
                    <Col md={4} className="mt-3">
                      <AvField
                        name="makeYear"
                        className="form-control"
                        label="Year"
                        type="number"
                        value={updateData ? vehicleDetails?.makeYear : ""}
                        validate={{
                          required: {
                            value: false,
                            errorMessage: "Please enter Make Year",
                          },
                        }}
                      />
                    </Col>
                    <Col md={4} className="mt-3">
                      <AvField
                        name="kiloMeter"
                        className="form-control"
                        label="Kilometer"
                        type="number"
                        value={updateData ? vehicleDetails?.kiloMeter : ""}
                        validate={{
                          required: {
                            value: false,
                          },
                        }}
                      />
                    </Col>
                    {/* <Col md={4} className="mt-3">
                      <AvField
                        name="initialPurchaseYear"
                        className="form-control"
                        label="Purchased Year"
                        type="number"
                        value={
                          updateData ? vehicleDetails?.initialPurchaseYear : ""
                        }
                        validate={{
                          required: {
                            value: false,
                            errorMessage: "Please enter Purchased Year",
                          },
                        }}
                      />
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}

          {updateType === "create" && (
            <>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col
                        md={6}
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <div className="mb-4 d-flex align-items-end">
                          <div className="w-100">
                            <label>
                              Name<span className="text-danger">*</span>
                            </label>
                            <AvField
                              name="name"
                              type="text"
                              className="form-control"
                              value={updateData ? vehicleDetails?.name : ""}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter a name",
                                },
                              }}
                            />
                          </div>
                          <div className="mx-4 d-flex align-items-end mb-1">
                            <p className="mb-1"> Featured</p>
                            <Switch
                              uncheckedIcon={<OffSymbol />}
                              checkedIcon={<OnSymbol />}
                              className="w-100 ms-2 mb-sm-8"
                              onColor="#EC942C"
                              onChange={v => {
                                setIsFeatured(v)
                              }}
                              checked={isFeatured ?? false}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-enter justify-content-start mb-2">
                          <div className="w-50 me-1">
                            <label>
                              Vehicle No<span className="text-danger">*</span>
                            </label>
                            <AvField
                              name="vehicleNumber"
                              type="text"
                              placeholder="e.g. KL00AA0000"
                              className="form-control"
                              value={
                                updateData ? vehicleDetails?.vehicleNumer : ""
                              }
                              helpMessage="e.g. KL00AA0000"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: "Please enter a name",
                                },
                                // pattern: {
                                //   value:
                                //     /^[A-Z]{2}[ -]?[0-9]{2}[ -]?[0-9]{2}[ -]?[0-9]{4}$/,
                                //   errorMessage:
                                //     "Please enter a valid Vehicle Number",
                                // },
                                // maxlength: {
                                //   value: 10,
                                //   errorMessage: "maximum 10 digit is allowed",
                                // },
                                // minlength: {
                                //   value: 8,
                                //   errorMessage: "minimum 8 digit is required",
                                // },
                              }}
                              onChange={e => {
                                setOnCheckVehicleNumber(e.target.value)
                                handleVehicleNumber(e.target.value)
                              }}
                            />

                            {onCheckVehicleNumber && (
                              <>
                                {vehicleNumber?.vehicleNumber === true ? (
                                  <small className="text-success">
                                    Available
                                  </small>
                                ) : (
                                  <small className="text-danger">
                                    Not Available
                                  </small>
                                )}
                              </>
                            )}
                          </div>
                          <div className="w-50">
                            <div className="w-100">
                              <label>
                                Category<span className="text-danger">*</span>
                              </label>
                              <Select
                                value={selectedMulti}
                                isMulti={true}
                                onChange={e => {
                                  handleMulti(e)
                                }}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                                isLoading={modelLoading}
                                isRequired={true}
                              />
                            </div>
                          </div>
                        </div>

                        <UseImageUploader
                          imageSize={4}
                          imagesPreview={images}
                          setImagesPreview={setImages}
                          uploadUrl={"/focusmotors"}
                          isMultiple
                        />
                      </Col>
                      <Col md={6} className="p-2 ">
                        <AvField
                          name="description"
                          type="textarea"
                          clasnName="form-control"
                          label="Description"
                          value={updateData ? vehicleDetails?.description : ""}
                          validate={{
                            required: {
                              value: false,
                              errorMessage: "Please enter a name",
                            },
                          }}
                          style={{ height: 120 }}
                        />

                        <div className="col-12 mt-2">
                          <Label htmlFor="lbltype" className="form-label">
                            Tags
                          </Label>
                          <ReactTags
                            tags={tags}
                            placeholder="Type TEXT and enter"
                            suggestions={suggestions}
                            delimiters={delimiters}
                            handleDelete={handleDeleteTag}
                            handleAddition={handleAddition}
                            handleDrag={handleDrag}
                            handleTagClick={handleTagClick}
                            inputFieldPosition="inline"
                            autocomplete
                            allowUnique
                            inline
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </>
          )}

          {updateData && (
            <>
              {updateType === "booking" && (
                <Col sm="8">
                  <Card>
                    <CardBody>
                      <Row className="py-2">
                        <Col md={4}>
                          <AvField
                            name="bookedDate"
                            type="date"
                            label="Booked Date"
                            value={bookedDate ? bookedDate : ""}
                            validate={{ date: { format: "MM/DD/YYYY" } }}
                          />
                        </Col>
                        <Col md={4}>
                          <label>Booked Client</label>
                          <Select
                            onInputChange={handleEnteredValue}
                            value={bookedClient}
                            placeholder={bookedClient}
                            onChange={handlerBookedClientFinalValues}
                            options={clientOptions}
                            classNamePrefix="select2-selection"
                            isLoading={modelLoading}
                          />
                        </Col>
                        <Col md={4}>
                          {/* <AvField
                            name="bookedBy"
                            clasnName="form-control"
                            label="Booked By"
                            type="text"
                            value={updateData ? vehicleDetails?.bookedBy : ""}
                            validate={{
                              required: {
                                value: false,
                                errorMessage: "Please enter a name",
                              },
                            }}
                          /> */}
                          <label>Booked By</label>
                          <Select
                            onInputChange={handleEnteredValueStaff}
                            // value={bookedStaff}
                            placeholder={bookedStaff}
                            onChange={handlerBookedStaffFinalValues}
                            options={staffBookedOptions}
                            classNamePrefix="select2-selection"
                            isLoading={modelLoading}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
              {updateType === "sold" && (
                <Col sm="8">
                  <Card>
                    <CardBody>
                      <Row className="py-2">
                        <Col md={4}>
                          <AvField
                            name="soldDate"
                            clasnName="form-control"
                            label="Sold Date"
                            type="date"
                            validate={{
                              required: {
                                value: false,
                                errorMessage: "Please enter a name",
                              },
                              date: {
                                format: "MM/DD/YYYY",
                                errorMessage: false,
                              },
                            }}
                            value={soldDate}
                          />
                        </Col>
                        <Col md={4}>
                          <label>Sold Client</label>
                          <Select
                            onInputChange={handleEnteredValues}
                            // value={soldClient}
                            placeholder={soldClient}
                            onChange={handlerSoldClientFinalValues}
                            options={clientOptions}
                            classNamePrefix="select2-selection"
                            isLoading={modelLoading}
                          />
                        </Col>
                        <Col md={4}>
                          <label>Sold By</label>
                          <Select
                            onInputChange={handleEnteredValueSold}
                            // value={soldStaff}
                            placeholder={soldStaff}
                            onChange={handlerSoldStaffFinalValues}
                            options={staffSoldOptions}
                            classNamePrefix="select2-selection"
                            isLoading={modelLoading}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </>
          )}
        </Row>

        <Col
          sm={updateType === "vehicle" || updateType === "create" ? "12" : "8"}
          className="px-3"
        >
          <div className="d-flex align-items-center justify-content-end py-2 mb-4">
            <Button className="px-5" color="warning" type="submit">
              {modelLoading && <Spinner />}
              {updateData ? "Update" : "Create"}
            </Button>

            {/* <Button className="px-5" color="warning" type="submit" disabled>
              {modelLoading && <Spinner />}
              {updateData ? "Update" : "Create"}
            </Button> */}
          </div>
        </Col>
      </AvForm>
    </div>
  )
}

export default Form

Form.propTypes = {
  updateData: PropTypes.bool,
  values: PropTypes.object,
}
export const ownerShip = [
  {
    id: 11,
    title: "First Owner",
    value: "first_owner",
  },
  {
    id: 22,
    title: "Second Owner",
    value: "second_owner",
  },

  {
    id: 33,
    title: "Third Owner",
    value: "third_owner",
  },
]

const FUEL_TYPE = [
  {
    id: 1,
    title: "DIESEL",
    value: "diesel",
  },
  {
    id: 2,
    title: "PETROL",
    value: "petrol",
  },
  {
    id: 3,

    title: "ELECTRIC",
    value: "electric",
  },
  {
    id: 4,
    title: "CNG",
    value: "cng",
  },
]

export const OffSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

export const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}
