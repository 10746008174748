import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

//redux
import { useSelector } from "react-redux"
import { LocalLogo } from "assets/images"
import img16px from "assets/images/Defualt/FOCUS 16X16.png"
import img20px from "assets/images/Defualt/FOCUS 40x40-1.png"
// import { img20px } from "assets/images/Defualt/logo 20*20.svg"

const Sidebar = props => {
  const { sideBarSm } = useSelector(state => ({
    // settings: state.Settings.settings,
    sideBarSm: state.Layout.sideBarSm,
  }))

  const primaryColor = ""
  return (
    <React.Fragment>
      <div
        className="vertical-menu"
        style={{ backgroundColor: primaryColor || "#ffff" }}
      >
        <div className="navbar-brand-box d-flex align-items-center justify-content-center">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={img20px} alt="company logo" height="45" />
            </span>
            <span className="logo-lg">
              <img src={LocalLogo} alt="" height="35" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light"></Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? (
            <SidebarContent props />
          ) : (
            <SidebarContent />
          )}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(mapStatetoProps, {})(withRouter(Sidebar))
