import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

import { connect } from "react-redux"

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [ui, setui] = useState(false)
  const [app, setapp] = useState(false)
  const [email, setemail] = useState(false)
  const [ecommerce, setecommerce] = useState(false)
  const [crypto, setcrypto] = useState(false)
  const [project, setproject] = useState(false)
  const [task, settask] = useState(false)
  const [contact, setcontact] = useState(false)
  const [blog, setBlog] = useState(false)
  const [component, setcomponent] = useState(false)
  const [form, setform] = useState(false)
  const [table, settable] = useState(false)
  const [chart, setchart] = useState(false)
  const [icon, seticon] = useState(false)
  const [map, setmap] = useState(false)
  const [extra, setextra] = useState(false)
  const [invoice, setinvoice] = useState(false)
  const [auth, setauth] = useState(false)
  const [utility, setutility] = useState(false)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setdashboard(!dashboard)
                    }}
                    to="/dashboard"
                  >
                    <i className="bx bx-home-circle me-2"></i>
                    Dashboard {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: dashboard })}
                  >
                    <Link to="/dashboard" className="dropdown-item">
                      Default
                    </Link>
                    <Link to="#" className="dropdown-item">
                      Saas
                    </Link>
                    <Link to="#" className="dropdown-item">
                      Crypto
                    </Link>
                    <Link to="#" className="dropdown-item">
                      Blog
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setui(!ui)
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-tone me-2"></i>
                    UI Elements <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname(
                      "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                      { show: ui }
                    )}
                  >
                    <Row>
                      <Col lg={4}>
                        <div>
                          <Link to="#" className="dropdown-item">
                            Alerts
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Buttons
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Cards
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Carousel
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Dropdowns
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Grid
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Images
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Lightbox
                          </Link>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div>
                          <Link to="#" className="dropdown-item">
                            Modals
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Range Slider
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Session Timeout
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Progress Bars
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Sweet-Alert
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Tabs & Accordions
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Typography
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Video
                          </Link>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div>
                          <Link to="#" className="dropdown-item">
                            General
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Colors
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Rating
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Notifications
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Breadcrumb
                          </Link>
                          <Link to="#" className="dropdown-item">
                            Drawer
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setapp(!app)
                    }}
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="bx bx-customize me-2"></i>
                    Apps <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    <Link to="#" className="dropdown-item">
                      Calendar
                    </Link>
                    <Link to="#" className="dropdown-item">
                      Chat
                    </Link>
                    <Link to="#" className="dropdown-item">
                      File Manager
                    </Link>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setemail(!email)
                        }}
                      >
                        Email <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: email })}
                      >
                        <Link to="#" className="dropdown-item">
                          Inbox
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Read Email
                        </Link>
                        <div className="dropdown">
                          <Link
                            className="dropdown-item dropdown-toggle arrow-none"
                            to="/#"
                            onClick={e => {
                              e.preventDefault()
                              setemail(!email)
                            }}
                          >
                            <span key="t-email-templates">Templates</span>{" "}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: email,
                            })}
                          >
                            <Link to="#" className="dropdown-item">
                              Basic Action
                            </Link>
                            <Link to="#" className="dropdown-item">
                              Alert Email
                            </Link>
                            <Link to="#" className="dropdown-item">
                              Billing Email
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setecommerce(!ecommerce)
                        }}
                      >
                        Ecommerce <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: ecommerce,
                        })}
                      >
                        <Link to="#" className="dropdown-item">
                          Products
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Product Detail
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Orders
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Customers
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Cart
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Checkout
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Shops
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Add Product
                        </Link>
                      </div>
                    </div>

                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setcrypto(!crypto)
                        }}
                      >
                        Crypto <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: crypto })}
                      >
                        <Link to="#" className="dropdown-item">
                          Wallet
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Buy/Sell
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Exchange
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Lending
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Orders
                        </Link>
                        <Link to="#" className="dropdown-item">
                          KYC Application
                        </Link>
                        <Link to="#" className="dropdown-item">
                          ICO Landing
                        </Link>
                      </div>
                    </div>

                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setproject(!project)
                        }}
                      >
                        Projects <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: project,
                        })}
                      >
                        <Link to="#" className="dropdown-item">
                          Projects Grid
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Projects List
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Project Overview
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Create New
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          settask(!task)
                        }}
                      >
                        Tasks <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: task })}
                      >
                        <Link to="#" className="dropdown-item">
                          Task List
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Kanban Board
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Create Task
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setcontact(!contact)
                        }}
                      >
                        Contacts <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: contact,
                        })}
                      >
                        <Link to="#" className="dropdown-item">
                          User Grid
                        </Link>
                        <Link to="#" className="dropdown-item">
                          User List
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Profile
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setBlog(!blog)
                        }}
                      >
                        Blog <div className="arrow-down" />
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: blog,
                        })}
                      >
                        <Link to="#" className="dropdown-item">
                          Blog List
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Blog Grid
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Blog Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setcomponent(!component)
                    }}
                  >
                    <i className="bx bx-collection me-2"></i>
                    Components <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: component })}
                  >
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setform(!form)
                        }}
                      >
                        Forms <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: form })}
                      >
                        <Link to="#" className="dropdown-item">
                          Form Elements
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Form Layouts
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Form Validation
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Form Advanced
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Form Editors
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Form File Upload
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Form Xeditable
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Form Repeater
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Form Wizard
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Form Mask
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Transfer List
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          settable(!table)
                        }}
                      >
                        Tables <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: table })}
                      >
                        <Link to="#" className="dropdown-item">
                          Basic Tables
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Data Tables
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Responsive Table
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Editable Table
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Drag & Drop Table
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setchart(!chart)
                        }}
                      >
                        Charts <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: chart })}
                      >
                        <Link to="#" className="dropdown-item">
                          Apex charts
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Chartjs Chart
                        </Link>
                        <Link to="#" className="dropdown-item">
                          E Chart
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Toast UI Chart
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Sparkline Chart
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Knob Chart
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Re Chart
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          seticon(!icon)
                        }}
                      >
                        Icons <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: icon })}
                      >
                        <Link to="#" className="dropdown-item">
                          Boxicons
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Material Design
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Dripicons
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Font awesome
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setmap(!map)
                        }}
                      >
                        Maps <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: map })}
                      >
                        <Link to="#" className="dropdown-item">
                          Google Maps
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Vector Maps
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Leaflet Maps
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setextra(!extra)
                    }}
                  >
                    <i className="bx bx-file me-2"></i>
                    Extra pages <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: extra })}>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setinvoice(!invoice)
                        }}
                      >
                        Invoices <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: invoice,
                        })}
                      >
                        <Link to="#" className="dropdown-item">
                          Invoice List
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Invoice Detail
                        </Link>
                      </div>
                    </div>

                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setauth(!auth)
                        }}
                      >
                        Authentication <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: auth })}
                      >
                        <Link to="#" className="dropdown-item">
                          Login
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Login 2
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Register
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Register 2
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Recover Password
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Recover Password 2
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Lock Screen
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Lock Screen 2
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Confirm Mail
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Confirm Mail 2
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Email Verification
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Email Verification 2
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Two Step Verification
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Two Step Verification 2
                        </Link>
                      </div>
                    </div>

                    <div className="dropdown">
                      <Link
                        className="dropdown-item dropdown-toggle arrow-none"
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          setutility(!utility)
                        }}
                      >
                        Utility <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: utility,
                        })}
                      >
                        <Link to="#" className="dropdown-item">
                          Starter Page
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Maintenance
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Coming Soon
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Timeline
                        </Link>
                        <Link to="#" className="dropdown-item">
                          FAQs
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Pricing
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Error 404
                        </Link>
                        <Link to="#" className="dropdown-item">
                          Error 500
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(connect(mapStatetoProps, {})(Navbar))
